<template>
  <file-pond
    name="file"
    ref="pond"
    accepted-file-types="image/jpeg, image/png"
    :server="server"
    credits="false"
    :files="files"
    allowRevert="false"
  />
</template>

<script>
import { getApiUrl } from "@/utils";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  components: {
    FilePond,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    pageId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      files: [],
    };
  },

  computed: {
    server() {
      const appId = this.$route.params.id;
      const token = localStorage.getItem("token");

      return {
        url: `${getApiUrl(appId)}/module/page-content/${this.pageId}/items/${
          this.item.id
        }/image`,
        process: this.process,
        revert: this.revert,
        remove: this.remove,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    },
  },

  mounted() {
    if (this.item.content) {
      this.files.push({
        source: this.item.content,
        options: {
          type: "local",
        },
      });
    }
  },

  methods: {
    process(fieldName, file, metadata, load, error, progress, abort) {
      const appId = this.$route.params.id;

      this.$store
        .dispatch("pageContent/uploadImage", {
          appId,
          pageId: this.pageId,
          itemId: this.item.id,
          file,
        })
        .then(response => {
          this.files.push({
            source: response.data,
            options: {
              type: "local",
            },
          });
          load(response.data);
        })
        .catch(err => {
          abort(err);
        });
    },
    remove(source, load, error) {
      const appId = this.$route.params.id;

      this.$store
        .dispatch("pageContent/removeImage", {
          appId,
          pageId: this.pageId,
          itemId: this.item.id,
        })
        .then(() => {
          this.files = [];
          load();
        })
        .catch(() => {
          error();
        });
    },
  },
};
</script>
