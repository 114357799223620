<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-card outlined class="mt-2">
        <v-toolbar flat color="grey lighten-3">
          <v-list-item class="pl-0 pr-0">
            <v-list-item-content>
              <v-list-item-title class="title">
                Edit Page - {{ page.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar>
        <div class="pa-5">
          <v-expansion-panels multiple focusable v-if="page.items.length > 0">
            <v-expansion-panel
              v-for="(item, index) in page.items"
              :key="item.id"
            >
              <v-expansion-panel-header>
                <v-row no-gutters>
                  <v-col cols="6">{{ item.label }}</v-col>
                  <v-col class="text-right pr-4"
                    >Last Updated: {{ item.formatted_dates.updated_at }} by
                    {{ item.updated_by_user.full_name }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <v-text-field
                  :label="item.label"
                  outlined
                  v-if="item.type === 'text'"
                  v-model="page.items[index].content"
                ></v-text-field>
                <v-textarea
                  :label="item.label"
                  outlined
                  v-else-if="item.type === 'textarea'"
                  v-model="page.items[index].content"
                ></v-textarea>
                <tiptap
                  class="mb-5"
                  :label="item.label"
                  v-else-if="item.type === 'wysiwyg'"
                  v-model="page.items[index].content"
                />
                <ImageItem
                  v-else-if="item.type === 'image'"
                  :item="item"
                  :pageId="page.id"
                />
                <VideoItem
                  v-else-if="item.type === 'video'"
                  :item="item"
                  :pageId="page.id"
                />
                <v-btn
                  depressed
                  color="accent"
                  :loading="savingChanges"
                  v-if="
                    item.type === 'text' ||
                    item.type === 'textarea' ||
                    item.type === 'wysiwyg'
                  "
                  @click="saveChanges"
                  >Save Content</v-btn
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <p v-else class="mb-0">
            There is nothing that can be edited on this page.
          </p>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ImageItem from "./components/ImageItem";
import VideoItem from "./components/VideoItem";
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    ImageItem,
    VideoItem,
    Tiptap,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Page Content",
          disabled: false,
          exact: true,
          to: {
            name: "module-pagecontent",
          },
        },
      ],
      savingChanges: false,
    };
  },

  created() {
    this.breadcrumbs.push({
      text: this.page.name,
      disabled: true,
    });
  },

  computed: {
    page() {
      return this.$store.state.pageContent["page"];
    },
  },

  methods: {
    saveChanges: function () {
      this.savingChanges = true;

      let fields = {
        name: this.page.name,
        slug: this.page.slug,
        items: [],
      };

      this.page.items.forEach((item) => {
        fields.items.push({
          id: item.id,
          label: item.label,
          type: item.type,
          content: item.content,
        });
      });

      this.$store
        .dispatch("pageContent/savePage", {
          appId: this.$route.params.id,
          pageId: this.$route.params.pageId,
          isEditing: true,
          fields: {
            ...fields,
          },
        })
        .then(() => {
          this.savingChanges = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Page Successfully Saved",
          });
        })
        .catch(() => {
          this.savingChanges = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Error! Save was Unsuccessful",
          });
        });
    },
  },
};
</script>
